<template>
  <v-dialog v-model="dialog" max-width="850px" width="800px" @click:outside="is_sms_send=false">
    <template v-slot:activator="{ on }">
      <v-btn style="margin: 0" color="primary" v-on="on" block> {{ $t('Haz clic aquí para confirmar') }}</v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline"> {{ $t('Regístrate') }} </span>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="user.nombre" :label="$t('Nombre')" :rules="name_rules" required/>
            </v-col>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="user.apellidos" :label="$t('Apellidos')" :rules="surname_rules" required/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <v-text-field v-model="user.email" label="Email" :rules="email_rules" required/>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3" md="4" class="pt-0 pb-0">
              <v-autocomplete
                  v-model="user.prefijo"
                  :items="country_items"
                  item-text="phone_code"
                  hide-details
                  @change="handlePrefixChange"
              >
                <template v-slot:item="{ item }">
                  <country-flag :country='item.code2' size='normal'/>
                  <span class="ml-2 mt-2">+{{ item.phone_code }}</span>
                </template>
                <template v-slot:selection="{ item }">
                  <country-flag class="mb-1" :country='item.code2' size='normal'/>
                  <span class="ml-2 mb-1">+{{ item.phone_code }}</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="7" md="8" class="pb-0">
              <v-text-field v-model="user.telefono" :label="$t('Teléfono')" :rules="phone_rules" required/>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0 pb-0">
              <v-select
                  v-model="user.sexo"
                  :items="sexos_items"
                  item-text="text"
                  item-value="value"
                  :label="$t('Sexo')"
                  :rules="sexo_rules"
                  required
              />
            </v-col>
          </v-row>
        </v-form>

        <v-card-actions>
          <v-row justify="center" align="center" align-content="center">
            <div v-if="is_sms_send" class="d-flex align-content-center align-center">
              <v-col>
                <v-text-field style="width: 10vh" v-model="sms_code" :label="$t('Código SMS')" required/>
              </v-col>
              <v-col>
                <v-btn text @click="register">{{ $t('Confirmar') }}</v-btn>
              </v-col>
            </div>
            <v-col class="d-flex justify-end">
              <v-btn :disabled="!valid" text @click="handle_sms_code">{{ $t('Continuar') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import store from '@/store'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
export default {
  components: {
    CountryFlag: () => import('vue-country-flag')
  },
  name: 'Registro',
  data(){
    return {
      is_sms_send: false,
      dialog: false,
      valid: false,
      user: {
        nombre: "",
        apellidos: "",
        email: "",
        password: "",
        telefono: "",
        ciudad: null,
        sexo: null,
        origen: 'w',
        prefijo:  '34'
      },
      sms_code: null,

      cities_items: [],
      country_items: [],
      sexos_items: [
        { value: 'f', text: this.$t('Mujer') },
        { value: 'm', text: this.$t('Hombre') }
      ],

      name_rules: [
        v => !!v || this.$t('El nombre es obligatorio'),
        v => v.length >= 3 || this.$t('El nombre debe tener al menos 3 caracteres')
      ],
      surname_rules: [
        v => !!v || this.$t('Los apellidos son obligatorios'),
        v => v.length >= 3 || this.$t('Los apellidos deben tener al menos 3 caracteres')
      ],
      email_rules: [
        v => !!v || this.$t('El email es obligatorio'),
        v => /.+@.+/.test(v) || this.$t('El email debe ser válido')
      ],
      phone_rules: [
        phone => !!phone || this.$t('El teléfono es obligatorio'),
        phone => /^[0-9]+$/.test(phone) || this.$t('El número de móvil debe tener solo cifras'),
        phone => this.validatePhone(phone) || this.$t('El número de móvil no es válido para el prefijo seleccionado')
      ],
      sexo_rules: [
        v => !!v || this.$t('El sexo es obligatorio')
      ]
    }
  },

  methods: {
    validatePhone(phone) {
      const phoneNumber = parsePhoneNumberFromString(`+${this.user.prefijo}${phone}`)
      return phoneNumber && phoneNumber.isValid()
    },
    handlePrefixChange() {
      let phone;
      this.$refs.form.inputs.forEach((input, index) => {
        if (input.label === 'Teléfono') {
          phone = input
        }
      })
      phone.validate()
     },
    async register() {
      if (this.$refs.form.validate()) {
        if (this.sms_code != this.$store.state.sms_code) {
          this.$notify({
          title: this.$t('Error'),
          text: this.$('Se ha producido un error'),
          style: 'error'
          })
          return
        }
        this.dialog = false
        this.$emit('save_reserva', this.user)
      }
    },
    async get_cities(){
      this.cities_items = await this.$store.dispatch('common/get_cities', {
        url: window.location.hostname
      })
    },

    async get_countries(){
      this.country_items = await this.$store.dispatch('common/get_countries')
    },

    async handle_sms_code () {
      if (!localStorage.getItem('timeStamp')) {
        localStorage.setItem('timeStamp', new Date().getTime().toString())
      }else{
        localStorage.removeItem('timeStamp')
        localStorage.setItem('timeStamp', new Date().getTime().toString())
      }


      let code = Math.floor(1000 + Math.random() * 9000)
      this.$store.commit('set_sms_code', code)



      setInterval(() =>{
        let now = new Date().getTime()
        let timeStamp = localStorage.getItem('timeStamp')
        // 5 minutes = 300000
        if(now - timeStamp > 300000){
          this.valid = true
        }
      }, 1000)

      try {
        await this.$store.dispatch('common/send_sms_code', {
          url: window.location.hostname,
          phone: this.user.telefono,
          email: this.user.email,
          code: code,
          locale: this.$i18n.locale
        })

        this.valid = false
        this.is_sms_send = true

        this.$notify({
          title: this.$t('Atención'),
          text: this.$t('Te hemos enviado un código SMS para verificar tu cuenta')
        })
      } catch (e) {
        if (e.response && e.response.status === 409) {
          this.$notify({
            title: this.$t('Error'),
            text: this.$t('Ya eres cliente, vamos a redirigirte a la página de reservas'),
            style: 'error'
          })

          store.commit('set_loading', true)

          let url = 'https://www.elhadepilacionlaser.com/reservas/'

          if (this.$store.state.centro.centro.idEmpresa === 109) {
            url = 'https://centrielha.it/prenotazioni/'
          }

          setTimeout(() => {
            store.commit('set_loading', false)
            window.location.replace(url)
          }, 3000);

          return
        }
        this.$notify({
          title: this.$t('Error'),
          text: this.$t('Se ha producido un error al realizar la reserva'),
          style: 'error'
        })
      }

    }
  },

  mounted() {
    this.get_countries()
    switch (window.location.hostname.split('.')[2]) {
      case 'com':
        this.user.prefijo = '34'
        break
      case 'it':
        this.user.prefijo = '39'
        break
      case 'fr':
        this.user.prefijo = '33'
        break
    }
    if(this.centro){
      this.user.ciudad = this.centro.city.id
    }
  },

  computed: {
    centro(){
      return this.$store.state.centro.centro
    }
  }
}
</script>
